// CreateWebsite.js

import React, { useState } from "react";
import { toast } from "react-toastify";
import Card from "../components/Card";
//import BusinessHoursSection from "../components/BusinessHoursSection";
import FormFields from "../components/FormFields";
import LoadingScreen from "../components/LoadingScreen";
import { initialBusinessHours, initialFormData } from "../utils/initialStates";
import { editWebsite } from "../services/apiService";
import useLoadingText from "../hooks/useLoadingText";
import flattenFormData from "../utils/flattenFormData";

/**
 * Component for creating a new website.
 *
 * @returns {JSX.Element} The CreateWebsite component.
 */
const WebsiteEditor = () => {
  const [formData, setFormData] = useState(initialFormData.sections);
  const [businessHours, setBusinessHours] = useState(initialBusinessHours);
  const [loading, setLoading] = useState(false);
  const currentText = useLoadingText(loading);

  /**
   * Handles form input changes and updates the state.
   *
   * @param {Object} event - The event object from the input change.
   */
  const handleChange = (sectionIndex, fieldName, value) => {
    const updatedFormData = formData.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          fields: {
            ...section.fields,
            [fieldName]: value,
          },
        };
      }
      return section;
    });
    setFormData(updatedFormData);
  };

  /**
   * Handles form submission, sends data to the API, and handles the response.
   *
   * @param {Object} event - The event object from the form submission.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Flatten the formData before sending
    const flatFormData = flattenFormData(formData);

    try {
      // Combine flatFormData with businessHours and send it as payload
      const payload = { ...flatFormData, businessHours };
      const result = await editWebsite(payload);

      if (result) {
        toast.success(`Website created successfully!`);
      } else {
        toast.error("Website creation failed!");
      }

      setFormData(initialFormData.sections); // Reset formData if needed
      setBusinessHours(initialBusinessHours); // Reset businessHours if needed
    } catch (error) {
      toast.error(`An error occurred: ${error.message}. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dark:bg-gray-900 flex items-center justify-center min-h-screen">
      {loading ? (
        <LoadingScreen text={currentText} />
      ) : (
        <div className="bg-gray-100 dark:bg-gray-900 w-full">
          <div className="w-full space-y-2">
            <Card>
              <h1 className="mt-2 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-200">
                Website Editor
              </h1>
              <h3 className="mt-2 text-center text-md font-normal text-gray-900 dark:text-gray-200">
                You can use this form to edit any customers' website. Only the Dealership Identifiers are required, so you can update only the information that has changed.
              </h3>
              <form
                noValidate
                onSubmit={handleSubmit}
                className="mt-8 space-y-6"
              >
                {formData.map((section, sectionIndex) => (
                  <FormFields
                    key={section.title}
                    section={section}
                    sectionIndex={sectionIndex}
                    handleChange={handleChange}
                  />
                ))}
                <div>
                  <hr className="py-6" />
                  <button
                    type="submit"
                    className="group relative w-25 mx-auto flex justify-center mt-20 mb-10 py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Create Website
                  </button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebsiteEditor;
