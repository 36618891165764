import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";


const ToolTip = ({ toolTip }) => {
  const [show, setShow] = useState(false);
  const tooltipRef = useRef(null); // Ref for the tooltip container

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShow(false); // Close the tooltip if click is outside
    }
  };

  useEffect(() => {
    if (show) {
      // Add event listener to document when tooltip is shown
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener from document when tooltip is hidden
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]); // Only re-run the effect if show changes

  return (
    <div className="relative" ref={tooltipRef}>
      <button
      type="button"
        onClick={() => setShow(!show)}
        className="p-1 bg-gray-200 dark:bg-gray-950 rounded-full text-sm"
      >
        ?
      </button>
      {show && (
        <div className="absolute z-10 left-0 mt-2 w-80 bg-white dark:bg-gray-950 p-4 shadow-lg rounded-md">
          <p className="text-sm text-gray-700 dark:text-white">{toolTip.description}</p>
          {toolTip.imageSrc && (
            <img
              src={toolTip.imageSrc}
              alt="Tooltip visual"
              className="mt-2 max-w-full rounded-md"
            />
          )}
        </div>
      )}
    </div>
  );
};

ToolTip.propTypes = {
  toolTip: PropTypes.shape({
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.string,
  }).isRequired,
};

export default ToolTip;
