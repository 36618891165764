import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Login from './pages/Login'
import AdminPanel from './pages/AdminPanel'
import CreateWebsite from './pages/CreateWebsite'
import Layout from './components/Layout' // Import the Layout component
import './assets/styles/index.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import PasswordReset from './pages/ResetPassword'
import WebsiteEditor from './pages/EditWebsite'

const PrivateRoute = (props) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return isAuthenticated ? props.element : <Navigate to="/" />
}

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <div className="h-full">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<PasswordReset />} />{" "}
          {/* New route for PasswordReset */}
          <Route
            path="/admin/*"
            element={<PrivateRoute element={<Layout />} />}
          >
            <Route index element={<AdminPanel />} />
            <Route path="websites/create-website" element={<CreateWebsite />} />
            <Route path="websites/edit-website" element={<WebsiteEditor />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App
