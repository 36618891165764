import React from "react";
import PropTypes from "prop-types";

export const TextInputField = ({
  name,
  placeholder,
  label,
  value,
  onChange,
  maxLength,
  type,
}) => (
  <div style={{marginBottom:"1.5rem"}}>
    <label htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      type={type || "text"} // default to "text" if type is not provided
      maxLength={maxLength} // apply the maxLength if provided
      required
      className="flex rounded-md relative w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
);

TextInputField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number, // Add maxLength to propTypes
  type: PropTypes.string, // Add type to propTypes
};

export const CheckboxField = ({ name, label, checked, onChange }) => (
  <div className="flex items-center">
    <input
      id={name}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      className="mr-2 h-4 w-4 text-indigo-500 focus:ring-indigo-500 border-gray-300 dark:border-gray-700 rounded"
    />
    <label
      htmlFor={name}
      className="text-sm font-medium text-gray-700 dark:text-gray-200"
    >
      {label}
    </label>
  </div>
);

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const DropdownField = ({ name, label, options, selected, onChange }) => (
  <div>
    <label>{label}</label>
    <select
      id={name}
      name={name}
      label={label}
      value={selected}
      onChange={onChange}
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

DropdownField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
