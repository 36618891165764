import React from 'react'

/**
 * Reusable Card component with Tailwind CSS.
 *
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content of the card.
 * @returns {JSX.Element} The rendered card component.
 */
function Card({ children }) {
  return (
    <div className="bg-white dark:bg-gray-800 p-6 overflow-contain rounded-lg shadow-md transition-colors duration-300">
      {children}
    </div>
  )
}

export default Card
