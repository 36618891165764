import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAc0yVfHhw3OacoORT9i14gr2o7CFfMOqk',
  authDomain: 'dc-admin-panel.firebaseapp.com',
  projectId: 'dc-admin-panel',
  storageBucket: 'dc-admin-panel.appspot.com',
  messagingSenderId: '801594884559',
  appId: '1:801594884559:web:37bfaf1d9c7c53a67952ba',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export { auth }
