import { signOut } from 'firebase/auth'
import { auth } from '../firebaseConfig'
import { useNavigate } from 'react-router-dom'

/**
 * Custom hook to handle authentication-related tasks.
 */
export const useAuth = () => {
  const navigate = useNavigate()

  /**
   * Handles the logout process.
   */
  const handleLogout = async () => {
    try {
      await signOut(auth)
      navigate('/') // Redirect to the login page
    } catch (error) {
      console.error('Error signing out', error)
    }
  }

  return {
    handleLogout,
  }
}
