import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebaseConfig'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Card from '../components/Card'
import PublicLayout from '../components/PublicLayout'

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Logged in successfully!"); // Display success toast
      navigate("/admin"); // Redirect to the admin panel after successful login
    } catch (error) {
      console.error("Error signing in", error);
      toast.error("Error signing in. Please check your credentials."); // Display error toast
    }
  };

  // Function to navigate to the reset-password route
  const handleResetPassword = () => {
    navigate("/reset-password");
  };

  return (
    <PublicLayout>
      <div className="min-h-screen flex items-center justify-center bg-blue-500 dark:bg-blue-900 py-12 px-4 sm:px-6 lg:px-8">
        <Card>
          <div className="max-w-xs w-full space-y-8">
            <div>
              <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-200">
                DealerClick Admin Panel
              </h2>
            </div>
            <form noValidate className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 dark:border-gray-700 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-gray-300 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 dark:border-gray-700 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-gray-300 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-500 focus:ring-indigo-500 border-gray-300 dark:border-gray-700 rounded"
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-sm text-gray-900 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
                <div className="flex items-center">
                  <div
                    onClick={handleResetPassword}
                    className="ml-2 block text-sm text-gray-900 dark:text-gray-300 cursor-pointer"
                  >
                    Reset Password
                  </div>
                </div>
              </div>
              <div className="w-full block">
                <button
                  type="submit"
                  className="group relative w-20 mx-auto flex justify-center py-2 px-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 dark:bg-blue-800 dark:hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </PublicLayout>
  );
}

export default Login
