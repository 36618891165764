import React from 'react'

/**
 * Header component for the layout.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleLogout - The logout handler function.
 * @returns {JSX.Element} The rendered component.
 */
function Header({ handleLogout }) {
  return (
    <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-800">
      <div>{/* You can add more header content here */}</div>
      <button
        onClick={handleLogout}
        className="bg-gray-100 hover:bg-red-100 text-gray-800 px-4 py-2 rounded"
      >
        Logout
      </button>
    </header>
  )
}

export default Header
