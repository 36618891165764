import React, { useState, useEffect } from "react";

/**
 * Layout component that wraps around the main content and provides a sidebar and header.
 *
 * @returns {JSX.Element} The rendered component.
 */
function PublicLayout({children}) {
  const [isDarkMode] = useState(false);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900">
          {children}
        </main>
      </div>
    </div>
  );
}

export default PublicLayout;
