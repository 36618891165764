import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import { useAuth } from '../hooks/useAuth'

/**
 * Layout component that wraps around the main content and provides a sidebar and header.
 *
 * @returns {JSX.Element} The rendered component.
 */
function Layout() {
  const [isDarkMode] = useState(false)
  const { handleLogout } = useAuth()

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [isDarkMode])

  return (
    <>
      <div className="bg-red-500 text-white">
        <h3>&nbsp;Under Construction</h3>
      </div>

      <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header handleLogout={handleLogout} />
          <main className="flex-1 p-4 overflow-y-auto bg-gray-100 dark:bg-gray-900">
            <Outlet /> {/* This will render any nested routes */}
          </main>
        </div>
      </div>
    </>
  );
}

export default Layout
