// businessHours.js

/**
 * Initial structure for business hours.
 * @type {Array<Object>}
 */
export const initialBusinessHours = [{ day: "", hours: "" }];

/**
 * Handles changes to the business hours by updating a specific field of an hour entry.
 * 
 * @param {Array<Object>} businessHours - The current array of business hours.
 * @param {Function} setBusinessHours - State setter function for business hours.
 * @param {number} index - Index of the business hour to update.
 * @param {string} field - The field of the business hour object to update ('day' or 'hours').
 * @param {string} value - New value to set for the specified field.
 */
export const handleBusinessHoursChange = (
  businessHours,
  setBusinessHours,
  index,
  field,
  value
) => {
  const newBusinessHours = businessHours.map((hour, i) => {
    if (i === index) {
      return { ...hour, [field]: value };
    }
    return hour;
  });
  setBusinessHours(newBusinessHours);
};

/**
 * Adds a new business hour entry with default values.
 * 
 * @param {Array<Object>} businessHours - The current array of business hours.
 * @returns {Array<Object>} A new array of business hours with the new entry added.
 */
export const handleAddBusinessHours = (businessHours) => {
  return [...businessHours, { day: "", hours: "" }];
};

/**
 * Removes a specific business hour entry based on the index.
 * 
 * @param {Array<Object>} businessHours - The current array of business hours.
 * @param {number} index - Index of the business hour to remove.
 * @returns {Array<Object>} A new array of business hours with the specified entry removed.
 */
export const handleRemoveBusinessHours = (businessHours, index) => {
  return businessHours.filter((_, i) => i !== index);
};

// PropTypes for the component (if these functions are used in a React component)
/*
BusinessHours.propTypes = {
  businessHours: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string,
    hours: PropTypes.string
  })),
  setBusinessHours: PropTypes.func,
  index: PropTypes.number,
  field: PropTypes.string,
  value: PropTypes.string
};
*/
