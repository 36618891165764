// apiService.js

/**
 * A service for interacting with the API to create websites.
 * @module apiService
 */

/**
 * Creates a new website with the provided payload.
 *
 * @async
 * @function editWebsite
 * @param {Object} payload - The data to be sent to the API for website creation.
 * @returns {Promise<string>} The API's response as a text.
 * @throws Will throw an error if the API request fails.
 */
export const editWebsite = async (payload) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const response = await fetch(process.env.REACT_APP_API_URL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.text();
  } catch (error) {
    console.error("Failed to create website:", error);
    throw error;
  }
};
