// BusinessHoursSection.js

import React from "react";
import {
  handleAddBusinessHours,
  handleBusinessHoursChange,
  handleRemoveBusinessHours,
} from "../utils/businessHours";
import { TextInputField } from "./InputFields";

const BusinessHoursSection = ({ businessHours, setBusinessHours }) => (
  <div className="business-hours-section">
    {businessHours.map((hour, index) => (
      <div key={index} className="flex gap-2 items-center justify-center mb-2">
        <TextInputField
          name={`day-${index}`}
          placeholder="Day (e.g., 'M-F')"
          value={hour.day}
          onChange={(e) =>
            handleBusinessHoursChange(
              businessHours,
              setBusinessHours,
              index,
              "day",
              e.target.value
            )
          }
        />
        <TextInputField
          name={`hours-${index}`}
          placeholder="Hours (e.g., '9AM - 5PM')"
          value={hour.hours}
          onChange={(e) =>
            handleBusinessHoursChange(
              businessHours,
              setBusinessHours,
              index,
              "hours",
              e.target.value
            )
          }
        />
        <button
          type="button"
          onClick={() =>
            setBusinessHours(handleRemoveBusinessHours(businessHours, index))
          }
          className="px-3 text-red-500 rounded-md"
          style={{marginBottom: "1.5rem"}}
        >
          X
        </button>
      </div>
    ))}
    <button
      type="button"
      onClick={() => setBusinessHours(handleAddBusinessHours(businessHours))}
      className="rounded-md"
    >
      + Add More Business Hours
    </button>
  </div>
);

export default BusinessHoursSection;
