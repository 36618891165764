import React, { useState } from "react";
import PropTypes from "prop-types";
import { CheckboxField, TextInputField, DropdownField } from "./InputFields";
import ToolTip from "./ToolTip";

const formatLabel = (text) => {
  return (
    text.charAt(0).toUpperCase() + text.slice(1).replace(/([A-Z])/g, " $1")
  );
};

const FormFields = ({
  section,
  sectionIndex,
  handleChange,
  businessHours,
  setBusinessHours,
}) => {
  // Determine if the section index is even or odd
  const isEven = sectionIndex % 2 === 0;
  // Apply a light gray background for even sections
  const sectionBackgroundClass = isEven
    ? "bg-gray-100 dark:bg-gray-900 text:gray-800 dark:text-gray-100  p-3 rounded-md "
    : "bg-blue-100 dark:bg-gray-900 dark:text-gray-100  p-3 rounded-md";

  // State to manage whether the section is collapsed
  const [isCollapsed, setIsCollapsed] = useState();

  // Function to toggle collapsed state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`pt-10 px-10 ${sectionBackgroundClass}`}>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold mb-1">{section.title}</h2>
        <button
          type="button"
          onClick={toggleCollapse}
          className="text-sm font-semibold"
          style={{ marginTop: "-4rem", marginRight: "-1.5rem" }}
        >
          {isCollapsed ? "Show Fields 🔽 " : "Hide Fields 🔼 "}
        </button>
      </div>
      <p className="text-xs font-semibold mb-8">{section.subtitle}</p>
      {!isCollapsed && (
        <div className="flex flex-wrap gap-4 mb-4 mt-2">
          {Object.keys(section.fields).map((fieldName) => {
            if (fieldName.endsWith("Options")) {
              return null; // Skip rendering this field, it's for dropdown options
            }

            const fieldData = section.fields[fieldName];
            // Check if the field is a custom component
            if (fieldData.component) {
              const Component = fieldData.component;
              return (
                <div key={fieldName} className="min-w-[250px]">
                  <Component
                    {...fieldData.props}
                    businessHours={businessHours}
                    setBusinessHours={setBusinessHours}
                    onChange={(e) =>
                      handleChange(sectionIndex, fieldName, e.target.value)
                    }
                  />
                </div>
              );
            }

            // Handle the line break/divider
            if (fieldData.type === "lineBreak") {
              return (
                <hr
                  key={fieldName}
                  className="m-0 p-0 w-full border-blue-200 "
                />
              );
            }

            // Handle the line break/divider
            if (fieldData.type === "lineBreak2") {
              return (
                <div
                  key={fieldName}
                  className="m-0 p-0 w-full border-none"
                />
              );
            }

            const fieldValue =
              fieldData.value !== undefined ? fieldData.value : fieldData;
            const fieldToolTip = fieldData.toolTip;
            const isCheckbox = typeof fieldValue === "boolean";
            const isDropdown = section.fields.hasOwnProperty(
              `${fieldName}Options`
            );
            const formattedLabel = formatLabel(fieldName);

            const label = fieldData.label ? fieldData.label : formattedLabel;
            const placeholder = fieldData.placeholder
              ? fieldData.placeholder
              : formattedLabel;

            // Determine the appropriate input component based on the field type
            let inputComponent;
            if (isDropdown) {
              inputComponent = (
                <DropdownField
                  key={fieldName}
                  name={fieldName}
                  label={label}
                  options={section.fields[`${fieldName}Options`]}
                  selected={fieldValue}
                  onChange={(e) =>
                    handleChange(sectionIndex, fieldName, e.target.value)
                  }
                />
              );
            } else if (isCheckbox) {
              inputComponent = (
                <CheckboxField
                  key={fieldName}
                  name={fieldName}
                  label={label}
                  placeholder={placeholder}
                  checked={fieldValue}
                  onChange={(e) =>
                    handleChange(sectionIndex, fieldName, e.target.checked)
                  }
                />
              );
            } else if (!fieldName.endsWith("Options")) {
              inputComponent = (
                <TextInputField
                  key={fieldName}
                  name={fieldName}
                  label={label}
                  placeholder={placeholder}
                  value={fieldValue}
                  onChange={(e) =>
                    handleChange(sectionIndex, fieldName, e.target.value)
                  }
                />
              );
            }

            return (
              <div
                key={fieldName}
                className="relative flex items-center gap-2 w-full sm:w-auto"
              >
                <div className="flex-grow">{inputComponent}</div>
                {fieldToolTip && <ToolTip toolTip={fieldToolTip} />}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

FormFields.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
  }).isRequired,
  sectionIndex: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default FormFields;
