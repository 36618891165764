// flattenFormData.js

// Utility function to flatten the formData before sending to the API
const flattenFormData = (sectionedFormData) => {
  return sectionedFormData.reduce((acc, currentSection) => {
    // Iterate over each field in the current section
    Object.keys(currentSection.fields).forEach((fieldName) => {
      // Check if the field name ends with 'Options'
      if (!fieldName.endsWith("Options")) {
        // If it's not an 'Options' field, add it to the accumulator
        const fieldValue = currentSection.fields[fieldName];
        // Check if fieldValue is an object with a 'value' property
        acc[fieldName] = fieldValue.hasOwnProperty("value")
          ? fieldValue.value
          : fieldValue;
      }
    });
    return acc;
  }, {});
};

export default flattenFormData;
