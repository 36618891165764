// initialStates.js

import BusinessHoursSection from "../components/BusinessHoursSection";
//import ImageUploadSection from "../components/ImageUploadSection";

/**
 * Initial form data state for the CreateWebsite component.
 */

export const initialFormData = {
  sections: [
    {
      title: "Dealership Identifiers",
      subtitle:
        "Enter the dealer ID and location ID for the dealership. These fields are required.",
      fields: {
        dealerId: {
          value: "",
          label: "Dealer ID*",
          placeholder: "1234",
        },

        locationId: {
          value: "",
          label: "Location ID*",
          placeholder: "4321",
        },
      },
    },
    {
      title: "Dealership Information",
      subtitle:
        "Enter the basic information for the dealership. Email and Phone number are optional. If you don't enter them, they won't be displayed on the website.",
      fields: {
        dealershipName: {
          value: "",
          label: "Dealership Name*",
          placeholder: "Only Put Letters and Numbers. NO SYMBOLS.",
          toolTip: {
            description: "This is the displayed name on the website.",
            imageSrc: "/images/dealership-name.png",
          },
        },
        tagline: {
          value: "Great Vehicles, Low Prices, Exceptional Service.",
          label: "Tagline, Slogan*",
          placeholder: "This is the Slogan or Tagline for the dealership.",
          toolTip: {
            description: "This is a single sentence slogan for the dealership.",
            imageSrc: "/images/tagline.png",
          },
        },
        address: {
          value: "",
          placeholder: "123 Main St, City, State, Zip",
          label: "Address*",
          toolTip: {
            description: "This is the address for the dealership.",
            imageSrc: "/images/address.png",
          },
        },
        email: {
          value: "",
          placeholder: "test@test.com",
          label: "Email for Leads and Contact Forms*",
          toolTip: {
            description:
              "This email is displayed in the contact section AND receives lead forms.",
            imageSrc: "/images/address.png",
          },
        },
        phoneNumber: {
          value: "",
          placeholder: "ONLY NUMBERS AND DASHES",
          label: "Phone Number*",
          toolTip: {
            description: "This is the official phone number of the business.",
            imageSrc: "/images/address.png",
          },
        },
      },
    },
    {
      title: "Business Hours*",
      subtitle:
        "Enter the business hours for the dealership. These fields are required.",
      fields: {
        customField: {
          component: BusinessHoursSection,
          props: {
            businessHours: [],
            setBusinessHours: [],
            value: "",
          },
        },
      },
    },
    {
      title: "Global Style Settings",
      subtitle:
        "Select the UI preferences for the dealership website.",
      fields: {
        colorScheme: {
          value: "",
          label: "Color Scheme",
        },
        faviconColor: {
          value: "",
          label: "Favicon Color",
          toolTip: {
            description: "This is a the color for the favicon on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        colorSchemeOptions: ["Blue", "Red", "Green", "Orange"],
        faviconColorOptions: ["Blue", "Red", "Green", "Orange"],
      },
    },
    {
      title: "Global UI Settings",
      subtitle:
        "Select the style preferences for the dealership website.",
      fields: {
        hasVehiclePayments: {
          value: false,
          label: "Wants Personalized Vehicle Payments",
          toolTip: {
            description:
              "This controls whether the vehicle payments section is displayed on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsFinancing: {
          value: true,
          label: "Wants Financing Section and Page",
          toolTip: {
            description:
              "This controls whether the financing sections and page are displayed on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        hasCarfax: {
          value: true,
          label: "Wants Carfax Section",
          toolTip: {
            description:
              "This controls whether the Carfax section is displayed on the site. It will only display if the vehicle has a Carfax report in either case.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        lineBreak1: {
          type: "lineBreak",
        },
        wantsTopBar: {
          value: true,
          label: "Wants Top Bar of Site",
          toolTip: {
            description:
              "This controls whether the top bar is displayed on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsFooter: {
          value: true,
          label: "Wants Footer of Site",
          toolTip: {
            description:
              "This controls whether the footer is displayed on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        lineBreak2: {
          type: "lineBreak",
        },
        wantsFooterSocial: {
          value: true,
          label: "Wants Social Media Links Section in Footer",
          toolTip: {
            description:"This controls whether the social media links are displayed in the footer.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsFacebook: true,
        wantsInstagram: true,
        wantsTwitter: true,
        lineBreak3: {
          type: "lineBreak",
        },

        mapUrl: {
          value: "Don't change this!",
          placeholder: "",
          toolTip: {
            description: "Enter the URL for the map of your dealership.",
            imageSrc: "/images/mapUrl.png",
          },
        },
        mapLinkUrl: {
          value: "Don't change this!",
          toolTip: {
            description: "Enter the text for the map link.",
            imageSrc: "/images/mapLinkText.png",
          },
        },
      },
    },
    {
      title: "Vehicle Card UI Settings",
      subtitle:
        "Select the style preferences for the dealership website. These are all required and control whether a certain feature is displayed on the website.",
      fields: {
        wantsCardFooter: {
          value: true,
          label: "Wants Card Footer",
          toolTip: {
            description:"This controls whether the card footer where it says 'Get it Tomorrow' and 'Local Delivery' is displayed on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        vehicleCardPrimaryFooterText: {
          value: "Get it Tomorrow",
          placeholder: "'Get it Tomorrow' will show by default.",
          label: "Primary Footer Text",
          toolTip: {
            description: "This is the primary footer text on the vehicle card.",
            imageSrc: "/images/primaryFooterText.png",
          },
        },
        vehicleCardSecondaryFooterText: {
          value: "LOCAL DELIVERY",
          placeholder: "",
          label: "Secondary Footer Text",
          toolTip: {
            description: "This is the secondary footer text on the vehicle card.",
            imageSrc: "/images/secondaryFooterText.png",
          },
        },
        wantsCardTopLabels: {
          value: true,
          label: "Wants 'Good Price' and 'Low Miles' Labels",
          toolTip: {
            description:"This controls whether 'Good Price' and 'Low Miles' labels are displayed on applicable vehicles. The calculation for 'Good Price' is based on the average price of similar vehicles in the market. The calculation for 'Low Miles' is based on the average miles of similar vehicles in the market.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        vehicleCardLowPrice: {
          value: "Low Price",
          label: "Low Price Label",
          placeholder: "'Good Price' will show on qualified vehicles.",
          toolTip: {
            description:"This controls whether the 'Good Price' label is displayed on the vehicle card footers.",
            imageSrc: "/images/circle-logo.png",
          },

        },
        vehicleCardLowMiles: {
          value: "Low Miles",
          label: "Low Miles Label",
          placeholder: "'Low Miles' will show on qualified vehicles.",
          toolTip: {
            description:"This controls whether the 'Low Miles' label is displayed on the vehicle card footers.",
            imageSrc: "/images/circle-logo.png",
          },
         
        },
        wantsFavorites: {
          value: true,
          label: "Wants Favorite Buttons",
          placeholder: "This controls whether the favorite buttons and functionality are used on the site.",
          toolTip: {
            description:"This controls whether the favorite button is displayed on the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
 
        lineBreak1: {
          type: "lineBreak",
        },
      
      },
    },
    {
      title: "Vehicle Disclaimer",
      subtitle:
        "This is the disclaimer shown on the bottom of the site regarding the pricing and availability of vehicles.",
      fields: {
        disclaimer: {
          value: "The estimated monthly payments and estimated down payments listed on this inventory page are based on a 720 credit score and a 72 month term with $100,000 annual gross income. Actual terms may vary based on individual creditworthiness, loan amount, and length of term. Prices listed may exclude additional fees and taxes. Please contact us for more information on financing options and terms.",
          label: "Vehicle Disclaimer",
          placeholder: "A default disclaimer will show if you don't enter anything.",
          toolTip: {
            description:"This is the disclaimer shown on the bottom of the site regarding the pricing and availability of vehicles.",
            imageSrc: "/images/circle-logo.png",
          },
        },
      },
    },
    {
      title: "Homepage UI Settings",
      subtitle:
        "Select the style preferences for the dealership website. These are all required and control whether a certain feature is displayed on the website.",
      fields: {
        wantsBrowseByType: {
          value: true,
          label: "Wants Browse By Type Section",
          toolTip: {
            description:"This controls whether the browse by type section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsSpecialtyTypes: {
          value: true,
          label: "Wants Specialty Types Section",
          toolTip: {
            description:"This controls whether the specialty types section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        lineBreak1: {
          type: "lineBreak",
        },

        wantsInventoryScroll: {
          value: true,
          label: "Wants Inventory Scroll",
          toolTip: {
            description:"This controls whether the inventory scroll section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        inventoryScrollNumber: {
          value: "10",
          label: "Number of Vehicles to Display on Homepage Inventory Scroll",
          placeholder: "10 will show by default.",
          toolTip: {
            description:"This controls how many vehicles are displayed on the inventory scroll section of the homepage.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        lineBreak2: {
          type: "lineBreak",
        },

        wantsFinancingSection: {
          value: true,
          label: "Wants Financing Section on Homepage",
          toolTip: {
            description:"This controls whether the financing section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsTradeSection: {
          value: true,
          label: "Wants Trade-In Section on Homepage",
          toolTip: {
            description:"This controls whether the trade-in section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsServiceSection: {
          value: true,
          label: "Wants Service Section on Homepage",
          toolTip: {
            description:"This controls whether the service section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        wantsTestimonialScroll: {
          value: true,
          label: "Wants Testimonial Scroll on Homepage",
          toolTip: {
            description:"This controls whether the testimonial scroll section is displayed on the homepage of the site.",
            imageSrc: "/images/circle-logo.png",
          },
        },
      },
    },
    // {
    //   title: "Homepage Hero Image",
    //   subtitle:
    //     "Upload the images for the homepage hero. You can choose the default image or upload your own. You must have legal right to use images.",
    //   fields: {
    //     customField: {
    //       component: ImageUploadSection,
    //     },
    //   },
    // },
    // {
    //   title: "Homepage Financing Image",
    //   subtitle:
    //     "Upload the images for the homepage hero. You can choose the default image or upload your own. You must have legal right to use images.",
    //   fields: {
    //     customField: {
    //       component: ImageUploadSection,
    //     },
    //   },
    // },
    // {
    //   title: "Homepage Trade-In Image",
    //   subtitle:
    //     "Upload the images for the homepage hero. You can choose the default image or upload your own. You must have legal right to use images.",
    //   fields: {
    //     customField: {
    //       component: ImageUploadSection,
    //     },
    //   },
    // },
    // {
    //   title: "Homepage Service Image",
    //   subtitle:
    //     "Upload the images for the homepage hero. You can choose the default image or upload your own. You must have legal right to use images.",
    //   fields: {
    //     customField: {
    //       component: ImageUploadSection,
    //     },
    //   },
    // },
    {
      title: "Inventory List Page UI Settings",
      subtitle:
        "Select the style preferences for the dealership website. These are all required and control whether a certain feature is displayed on the website.",
      fields: {
        wantsSocialProof: {
          value: false,
          label: "Wants Social Proof Section",
          toolTip: {
            description:"This controls whether the social proof section is displayed on the inventory list page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
      },
    },
    {
      title: "Financing Page UI Settings",
      subtitle:
        "Select the style preferences for the dealership website. These are all required and control whether a certain feature is displayed on the website.",
      fields: {
        financingTitle: {
          value: "Get Pre-Qualified",
          label: "Financing Page Title",
          placeholder: "'Get the Best Financing' will show by default.",
          toolTip: {
            description:"This is the title for the financing page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        financingSubtitle: {
          value: "Get started today by filling out our secure online application.",
          label: "Financing Page Subtitle",
          placeholder: "'We have the best financing options' will show by default.",
          toolTip: {
            description:"This is the subtitle for the financing page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        finContactTitle: {
          value: "Financing Application",
          label: "Financing Form Title",
          placeholder: "'Contact us for more information' will show by default.",
          toolTip: {
            description:"This is the contact title for the financing page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
      },
    },
    {
      title: "Contact Page UI Settings",
      subtitle:
        "Select the style preferences for the dealership website. These are all required and control whether a certain feature is displayed on the website.",
      fields: {
        contactFormTitle: {
          value: "Get in Touch",
          label: "Contact Page Title",
          placeholder: "'Contact Us' will show by default.",
          toolTip: {
            description:"This is the title for the contact page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        contactFormSubtitle: {
          value: "We would love to hear from you!",
          label: "Contact Page Subtitle",
          placeholder: "'We would love to hear from you' will show by default.",
          toolTip: {
            description:"This is the subtitle for the contact page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
        conContactTitle: {
          value: "Contact Form",
          label: "Contact Form Title",
          placeholder: "'Contact us for more information' will show by default.",
          toolTip: {
            description:"This is the contact title for the contact page.",
            imageSrc: "/images/circle-logo.png",
          },
        },
      },
    },
  ],
};

/**
 * Initial business hours state for the CreateWebsite component.
 */
export const initialBusinessHours = [{ day: "", hours: "" }];
