import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo.svg'

/**
 * Sidebar component for the layout.
 *
 * @returns {JSX.Element} The rendered component.
 */
function Sidebar() {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)

  return (
    <div className="flex flex-col w-64 bg-gray-50 dark:bg-gray-800 p-1">
      <div className="flex items-center justify-center h-16 mb-2  w-full">
        <Link
          to="/admin"
          className="text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 p-2 block"
        >
          <img src={Logo} alt="Logo" className="h-12" />
        </Link>
      </div>
      <ul>
        <li className="mb-2">
          <Link
            to="/admin"
            className="text-gray-700 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-gray-700 p-2 block w-full text-left"
          >
            Dashboard
          </Link>
        </li>
        <li className="mb-2 relative">
          <button
            onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
            className="text-gray-700 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-gray-700 p-2 block w-full text-left"
          >
            Customer Websites
            <span
              className={`absolute right-4 transform ${
                isSubmenuOpen ? "rotate-180" : "rotate-0"
              }`}
            >
              ▼
            </span>
          </button>
          {isSubmenuOpen && (
            <ul className="bg-gray-100 dark:bg-gray-700 rounded text-right">
              <li className="mb-2">
                <Link
                  to="/admin/websites/edit-website"
                  className="text-gray-700 dark:text-gray-300 hover:bg-blue-100 dark:hover:bg-blue-600 p-2 block"
                >
                  Website Editor
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/admin/websites/create-website"
                  className="text-gray-700 dark:text-gray-300 hover:bg-blue-100 dark:hover:bg-blue-600 p-2 block"
                >
                  New Website Generator
                </Link>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar
