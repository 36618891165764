import { useEffect, useState } from "react";

/**
 * Custom React hook that cycles through an array of texts.
 * It's primarily used to display a sequence of loading texts.
 *
 * @param {boolean} loading - A boolean value to start or stop the text cycling.
 * @returns {string} The current text to be displayed.
 */
const useLoadingText = (loading) => {
  const loadingTexts = [
    "Learning how to code",
    "Building the internet",
    "Optimizing performance",
    "Adding some magic",
  ];
  const [currentText, setCurrentText] = useState(loadingTexts[0]);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setCurrentText((prevText) => {
          const currentIndex = loadingTexts.indexOf(prevText);
          const nextIndex =
            currentIndex === loadingTexts.length - 1 ? 0 : currentIndex + 1;
          return loadingTexts[nextIndex];
        });
      }, 2000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // Omitting loadingTexts from the dependency array as it's a constant
    // If it's dynamic and changes, consider adding it to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return currentText;
};

export default useLoadingText;
