// LoadingScreen.js

import React from "react";
import PropTypes from "prop-types";

/**
 * A loading screen component that displays a spinner and a text message.
 *
 * @param {Object} props - Props for the LoadingScreen component.
 * @param {string} props.text - The text to display on the loading screen.
 * @returns {JSX.Element} The LoadingScreen component.
 */
const LoadingScreen = ({ text }) => (
  <div className="loadingContainer dark:text-gray-300">
    <div className="spinner"></div>
    <p className="dark:text-gray-300">{text}</p>
  </div>
);

LoadingScreen.propTypes = {
  text: PropTypes.string.isRequired,
};

export default LoadingScreen;
